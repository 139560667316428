import { Routes, Route, HashRouter } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Notification from "./pages/Notification";
import DailyBcaReport from "./pages/DailyBcaReport";
import AddBcaData from "./bca/AddBcaData";
import EditBcaData from "./bca/EditBcaData";
import AddSocialData from "./social/AddSocialData";
import EditSocialData from "./social/EditSocialData";
import SocialSecurityReport from "./pages/SocialSecurityReport";
import Messages from "./pages/Messages";
import BcaProfileReport from "./pages/BcaProfileReport";
import Profile from "./pages/Profile";
import User from "./pages/User";
import Login from "./Login";
import { useAppStore } from "./appStore";
import EditBcaDataProfile from "./bcareport/EditBcaData";

export default function App() {
    const utype = useAppStore((state) => state.utype);

    return (
        <>
            <HashRouter>
                <Routes>
                    <Route path="/" exact element={<Login />}></Route>

                    <>
                        <Route
                            path="/dashboard"
                            exact
                            element={<Dashboard />}
                        ></Route>
                        <Route
                            path="/messages"
                            exact
                            element={<Messages />}
                        ></Route>
                        <Route
                            path="/bca-profile-report"
                            exact
                            element={<BcaProfileReport />}
                        ></Route>
                        <Route
                            path="/notification"
                            exact
                            element={<Notification />}
                        ></Route>
                        <Route
                            path="/social-security-report"
                            exact
                            element={<SocialSecurityReport />}
                        ></Route>
                        <Route
                            path="/daily-bca-report"
                            exact
                            element={<DailyBcaReport />}
                        ></Route>
                        <Route
                            path="/add-bca"
                            exact
                            element={<AddBcaData />}
                        ></Route>
                        <Route
                            path="/edit-bca"
                            exact
                            element={<EditBcaData />}
                        ></Route>
                        <Route
                            path="/edit-bcaprofile"
                            exact
                            element={<EditBcaDataProfile />}
                        ></Route>
                        <Route
                            path="/add-social"
                            exact
                            element={<AddSocialData />}
                        ></Route>
                        <Route
                            path="/edit-social"
                            exact
                            element={<EditSocialData />}
                        ></Route>
                        <Route
                            path="/profile"
                            exact
                            element={<Profile />}
                        ></Route>
                        <Route
                            path="/dashboard"
                            exact
                            element={<User />}
                        ></Route>
                        <Route
                            path="/user"
                            exact
                            element={<User />}
                        ></Route>
                    </>
                    {/* {utype === "Admin" && (
                    )} */}
                </Routes>
            </HashRouter>
        </>
    );
}
