import create from "zustand";
import { persist } from "zustand/middleware";

let appStore = (set) => ({
  dopen: true,
  rows: [],
  username: '',
  useremail: '',
  password: '',
  token: '',
  utype: '',
  notifications: '',
  uid: '',
  bca: '',
  social: '',
  chatusers: '',
  isLoanwiser: '',
  updateIsLoanwiser: (isLoanwiser) => set((state) => ({ isLoanwiser: isLoanwiser })),
  updateChatUsers: (chatusers) => set((state) => ({ chatusers: chatusers })),
  updateSocial: (social) => set((state) => ({ social: social })),
  updateBca: (bca) => set((state) => ({ bca: bca })),
  updateUid: (notifications) => set((state) => ({ notifications: notifications })),
  updateNotifications: (notifications) => set((state) => ({ notifications: notifications })),
  updateUserEmail: (useremail) => set((state) => ({ useremail: useremail })),
  updateUtype: (utype) => set((state) => ({ utype: utype })),
  updateToken: (token) => set((state) => ({ token: token })),
  updateUsers: (users) => set((state) => ({ users: users })),
  updateUsername: (username) => set((state) => ({ username: username })),
  updatePassword: (password) => set((state) => ({ password: password })),
  setRows: (rows) => set((state) => ({ rows: rows })),
  updateDopen: (dopen) => set((state) => ({ dopen: dopen })),
});

appStore = persist(appStore, { name: "cdot_store_api" });
export const useAppStore = create(appStore);
