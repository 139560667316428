import React, { useEffect, useState } from "react";
import Sidenav from "../components/Sidenav";
import Box from "@mui/material/Box";
import Navbar from "../components/Navbar";
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import CountUp from 'react-countup';
import "../Dash.css";
import axios from "axios";
import { useAppStore } from "../appStore";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

export default function Dashboard() {
    const baseURL = process.env.REACT_APP_API_URL;
    const token = useAppStore((state) => state.token);
    const utype = useAppStore((state) => state.utype);
    const isLoanwiser = useAppStore((state) => state.isLoanwiser);
    const [area_coordinator_count, setAreaCoordinatorCount] = useState(0);
    const [bc_agent_count, setBcAgentCount] = useState(0);
    const [bc_social_scheme_count, setBcSocialSchemeCount] = useState(0);
    const [bca_transaction_count, setBcaTransactionCount] = useState(0);
    const [bca_transaction_total_count, setBcaTransactionTotalCount] = useState(0);
    const [bca_district_coordinator_count, setDistrictCoordinatorCount] = useState(0);
    const [user_availability_count, setUserAvailabilityCount] = useState(0);
    const [bcaSocialSchemeAc_count, setBcaSocialSchemeAcCount] = useState(0);
    const [bcaLoginAc_count, setBcaLoginAcCount] = useState(0);
    const [bcaTransactionAc_count, setBcaTransactionAcCount] = useState(0);
    const [encData, setEncData] = useState("");

    useEffect(() => {
        console.log('Loanwiser ', isLoanwiser);
        getDashboard();
        bcaTransactionAc();
        bcaLoginAc();
        bcaSocialSchemeAc();
        if (utype !== "Admin") {
            getAgentCount();
        }
        if (utype === "BC Agent") {
            getLoginKey();
        }
    }, []);

    const getLoginKey = async () => {
        let posturl = baseURL + "generatekey";

        await axios
            .get(
                posturl,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "Application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(function (res) {
                console.log("setEncData ", res.data);
                setEncData(res.data);

            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };

    const getAgentCount = async () => {
        let posturl = baseURL + "bcAgentAcCount";

        await axios
            .get(
                posturl,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "Application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(function (res) {
                // console.log("getAgentCount ", res.data);
                setBcAgentCount(res.data);

            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };

    const getDashboard = async () => {
        let posturl = baseURL + "getcounts";

        await axios
            .get(
                posturl,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "Application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(function (res) {
                console.log("getcounts ", res.data);
                setAreaCoordinatorCount(res.data.area_coordinator);
                if (utype === "Admin") {
                    setBcAgentCount(res.data.bc_agent);
                }
                setBcSocialSchemeCount(res.data.bca_social_scheme);
                setBcaTransactionCount(res.data.bca_transaction);
                setBcaTransactionTotalCount(res.data.bca_transaction_total);
                setDistrictCoordinatorCount(res.data.district_coordinator);

            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };

    const bcaTransactionAc = async () => {
        let posturl = baseURL + "bcaTransactionAc";

        await axios
            .get(
                posturl,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "Application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(function (res) {
                console.log("bcaTransactionAc ", res.data);
                // setUserAvailabilityCount(res.data.district_coordinator);

            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };

    const bcaLoginAc = async () => {
        let posturl = baseURL + "bcaLoginAc";

        await axios
            .get(
                posturl,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "Application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(function (res) {
                console.log("bcaLoginAc ", res.data);
                // setUserAvailabilityCount(res.data.district_coordinator);

            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };

    const bcaSocialSchemeAc = async () => {
        let posturl = baseURL + "bcaSocialSchemeAc";

        await axios
            .get(
                posturl,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "Application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(function (res) {
                console.log("bcaSocialSchemeAc ", res.data);
                // setUserAvailabilityCount(res.data.district_coordinator);

            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };

    const gotoLoanwiser = () => {
        console.log('Go to loanwiser');
        let url = "https://partneruat.loanwiser.in/cdot_partner.html";
        const win = window.open(url, '_blank');
        win.focus();
    }

    return (
        <>
            <div className="bgcolor">
                <Navbar />
                <Box height={70} />
                <Box sx={{ display: "flex" }}>
                    <Sidenav />
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <div className="mx-5">
                            {/* Cards Starting */}
                            <div className="grid grid-cols-3 gap-6">
                                {/* For Agent Starting */}
                                {utype === "BC Agent" && (
                                    <>
                                        <div className="lg:col-span-1 sm:col-span-4 xs:col-span-4">
                                            <div className="max-w-md mx-auto bg-sky-50 rounded-3xl shadow-lg overflow-hidden duration-150 transition hover:scale-105">
                                                <div className="flex justify-between">
                                                    <div className="pt-5 pl-10">
                                                        <AccountBalanceIcon className="text-4xl" />
                                                    </div>
                                                </div>
                                                <div className="md:flex">
                                                    <div className="pl-8 pt-3 pb-8">
                                                        <div className="uppercase tracking-wide text-sm text-sky-600 font-bold">Transaction Kiosk</div>
                                                        <div className="block mt-1 text-xl leading-tight font-medium text-black hover:underline">
                                                            CDOT
                                                            {/* <CountUp start={0} end={area_coordinator_count} delay={0} /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="lg:col-span-1 sm:col-span-4 xs:col-span-4">
                                            <div className="max-w-md mx-auto bg-sky-50 rounded-3xl shadow-lg overflow-hidden duration-150 transition hover:scale-105">
                                                <div className="flex justify-between">
                                                    <div className="pt-5 pl-10">
                                                        <AccountBalanceIcon className="text-4xl" />
                                                    </div>
                                                </div>
                                                <div className="md:flex">
                                                    <div className="pl-8 pt-3 pb-8">
                                                        <div className="uppercase tracking-wide text-sm text-sky-600 font-bold">Account Opening</div>
                                                        <div className="block mt-1 text-xl leading-tight font-medium text-black hover:underline">
                                                            CDOT
                                                            {/* <CountUp start={0} end={area_coordinator_count} delay={0} /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {isLoanwiser === "Yes" && (
                                            <>
                                                <div className="lg:col-span-1 sm:col-span-4 xs:col-span-4 cursor-pointer"
                                                // onClick={() => gotoLoanwiser()}
                                                >
                                                    <div className="max-w-md mx-auto bg-sky-50 rounded-3xl shadow-lg overflow-hidden duration-150 transition hover:scale-105">
                                                        <form method="POST" action="https://partneruat.loanwiser.in/cdot_partner.html">
                                                            <input type="hidden" value={encData} name="encData" />
                                                            <button type="submit" className="">
                                                                <div className="flex justify-between">
                                                                    <div className="pt-5 pl-10">
                                                                        <AccountBalanceIcon className="text-4xl" />
                                                                    </div>
                                                                </div>
                                                                <div className="md:flex">
                                                                    <div className="pl-8 pt-3 pb-8">
                                                                        <div className="uppercase tracking-wide text-sm text-sky-600 font-bold">Loanwiser</div>
                                                                        <div className="block mt-1 text-xl leading-tight font-medium text-black hover:underline">
                                                                            Click to Login...
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                                {/* For Agent Ending */}
                                {utype !== "BC Agent" && (
                                    <>
                                        <div className="lg:col-span-1 sm:col-span-4 xs:col-span-4">
                                            <div className="max-w-md mx-auto bg-sky-50 rounded-3xl shadow-lg overflow-hidden duration-150 transition hover:scale-105">
                                                <div className="flex justify-between">
                                                    <div className="pt-5 pl-10">
                                                        <PeopleAltRoundedIcon className="text-4xl" />
                                                    </div>
                                                </div>
                                                <div className="md:flex">
                                                    <div className="pl-8 pt-3 pb-8">
                                                        <div className="uppercase tracking-wide text-sm text-sky-600 font-bold">Area Coordinator</div>
                                                        <div className="block mt-1 text-3xl leading-tight font-medium text-black hover:underline">
                                                            <CountUp start={0} end={area_coordinator_count} delay={0} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="lg:col-span-1 sm:col-span-4 xs:col-span-4">
                                            <div className="max-w-md mx-auto bg-sky-50 rounded-3xl shadow-lg overflow-hidden duration-150 transition hover:scale-105">
                                                <div className="flex justify-between">
                                                    <div className="pt-5 pl-10">
                                                        <PeopleAltRoundedIcon className="text-4xl" />
                                                    </div>
                                                </div>
                                                <div className="md:flex">
                                                    <div className="pl-8 pt-3 pb-8">
                                                        <div className="uppercase tracking-wide text-sm text-sky-600 font-bold">District Coordinator</div>
                                                        <div className="block mt-1 text-3xl leading-tight font-medium text-black hover:underline">
                                                            <CountUp start={0} end={bca_district_coordinator_count} delay={0} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="lg:col-span-1 sm:col-span-4 xs:col-span-4">
                                            <div className="max-w-md mx-auto bg-sky-50 rounded-3xl shadow-lg overflow-hidden duration-150 transition hover:scale-105">
                                                <div className="flex justify-between">
                                                    <div className="pt-5 pl-10">
                                                        <PeopleAltRoundedIcon className="text-4xl" />
                                                    </div>
                                                </div>
                                                <div className="md:flex">
                                                    <div className="pl-8 pt-3 pb-8">
                                                        <div className="uppercase tracking-wide text-sm text-sky-600 font-bold">BC Agent</div>
                                                        <div className="block mt-1 text-3xl leading-tight font-medium text-black hover:underline">
                                                            <CountUp start={0} end={bc_agent_count} delay={0} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="lg:col-span-1 sm:col-span-4 xs:col-span-4">
                                            <div className="max-w-md mx-auto bg-sky-50 rounded-3xl shadow-lg overflow-hidden duration-150 transition hover:scale-105">
                                                <div className="flex justify-between">
                                                    <div className="pt-5 pl-10">
                                                        <PeopleAltRoundedIcon className="text-4xl" />
                                                    </div>
                                                </div>
                                                <div className="md:flex">
                                                    <div className="pl-8 pt-3 pb-8">
                                                        <div className="uppercase tracking-wide text-sm text-sky-600 font-bold">User Availability</div>
                                                        <div className="block mt-1 text-3xl leading-tight font-medium text-black hover:underline">
                                                            <CountUp start={0} end={127} delay={0} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="lg:col-span-1 sm:col-span-4 xs:col-span-4">
                                            <div className="max-w-md mx-auto bg-sky-50 rounded-3xl shadow-lg overflow-hidden duration-150 transition hover:scale-105">
                                                <div className="flex justify-between">
                                                    <div className="pt-5 pl-10">
                                                        <PeopleAltRoundedIcon className="text-4xl" />
                                                    </div>
                                                </div>
                                                <div className="md:flex">
                                                    <div className="pl-8 pt-3 pb-8">
                                                        <div className="uppercase tracking-wide text-sm text-sky-600 font-bold">BCA Transactions (Last 24 Hrs.)</div>
                                                        <div className="block mt-1 text-3xl leading-tight font-medium text-black hover:underline">
                                                            <CountUp start={0} end={bca_transaction_count} delay={0} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="lg:col-span-1 sm:col-span-4 xs:col-span-4">
                                            <div className="max-w-md mx-auto bg-sky-50 rounded-3xl shadow-lg overflow-hidden duration-150 transition hover:scale-105">
                                                <div className="flex justify-between">
                                                    <div className="pt-5 pl-10">
                                                        <PeopleAltRoundedIcon className="text-4xl" />
                                                    </div>
                                                </div>
                                                <div className="md:flex">
                                                    <div className="pl-8 pt-3 pb-8">
                                                        <div className="uppercase tracking-wide text-sm text-sky-600 font-bold">BCA Social Schemes (Last 24 Hrs.)</div>
                                                        <div className="block mt-1 text-3xl leading-tight font-medium text-black hover:underline">
                                                            <CountUp start={0} end={bc_social_scheme_count} delay={0} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            {/* Cards Ending */}
                        </div>
                    </Box>
                </Box>
            </div>
        </>
    );
}
