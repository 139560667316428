import { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useAppStore } from "../appStore";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function EditForm({ fid, closeEvent }) {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [status, setStatus] = useState("Active");
    const baseURL = process.env.REACT_APP_API_URL;
    const updateNotifications = useAppStore((state) => state.updateNotifications);
    const token = useAppStore((state) => state.token);

    useEffect(() => {
        setTitle(fid.notification_title);
        setContent(fid.notification_text);
        setStatus(fid.status);
    }, []);

    const createUser = async (url) => {
        let posturl = baseURL + "notify/" + fid.id;

        await axios
            .put(
                posturl,
                {
                    notification_title: title,
                    notification_text: content,
                    status: status,
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "Application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(function (res) {
                console.log(res.data);
                getUsers();
                closeEvent();
                Swal.fire("Submitted!", "Your file has been submitted.", "success");
            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };

    const getUsers = async () => {
        let posturl = baseURL + "notify";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updateNotifications(res.data);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleContentChange = (event) => {
        setContent(event.target.value);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };


    return (
        <>
            <Box sx={{ m: 2 }} />
            <Typography variant="h5" align="center">
                Add Notification
            </Typography>
            <IconButton
                style={{ position: "absolute", top: "0", right: "0" }}
                onClick={closeEvent}
            >
                <CloseIcon />
            </IconButton>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        value={title}
                        onChange={handleTitleChange}
                        label="Title"
                        size="small"
                        sx={{ marginTop: "30px", minWidth: "100%" }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-multiline-flexible"
                        value={content}
                        onChange={handleContentChange}
                        label="Content"
                        multiline
                        maxRows={4}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        variant="outlined"
                        sx={{ minWidth: "100%" }}
                        size="small"
                    >
                        <InputLabel id="branch-label">Status</InputLabel>
                        <Select
                            labelId="branch-label"
                            size="small"
                            value={status}
                            label="Status"
                            onChange={handleStatusChange}
                        >
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="Inactive">inactive</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" align="center">
                        <Button variant="contained" onClick={createUser}>
                            Submit
                        </Button>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}
