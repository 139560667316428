import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useAppStore } from "../appStore";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";


const schema = yup
    .object({
        name: yup.string().required(),
        email: yup.string().required(),
        // password: yup.string().required(),
        // confirmpassword: yup.string().required(),

    })
    .required();

export default function EditForm({ fid, closeEvent }) {

    const baseURL = process.env.REACT_APP_API_URL;
    const users = useAppStore((state) => state.users);
    const updateUsers = useAppStore((state) => state.updateUsers);
    const token = useAppStore((state) => state.token);
    const [availability, setAvailability] = useState("Offline");
    const [loanwiser, setLoanwiser] = useState("No");
    const [status, setStatus] = useState("Active");
    const [type, setType] = useState("User");
    const [password, setPassword] = useState(null);
    const utype = useAppStore((state) => state.utype);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    useEffect(() => {
        console.log("FID: ", fid);
        setAvailability(fid.availability);
        setLoanwiser(fid.loanwiser);
        setStatus(fid.status);
        setType(fid.type);
        reset(fid)

    }, []);

    const onSubmit = async (data) => {
        console.log('data ', data);
        Object.assign(data, { type: type });
        Object.assign(data, { availability: availability });
        Object.assign(data, { status: status });
        Object.assign(data, { loanwiser: loanwiser });
        if (password) {
            Object.assign(data, { password: password });
        }
        createUser(data);
    };

    const createUser = async (data) => {
        let posturl = baseURL + "userregister/" + fid.id;

        await axios
            .put(
                posturl,
                data,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "Application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(function (res) {
                console.log(res.data);
                getUsers();
                closeEvent();
                Swal.fire("Submitted!", "Your file has been submitted.", "success");
            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };

    const getUsers = async () => {
        let posturl = baseURL + "users";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updateUsers(res.data);
                console.log(res.data);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    const handleAvailabilityChange = (event) => {
        setAvailability(event.target.value);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    const handleLoanwiserChange = (event) => {
        setLoanwiser(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };


    return (
        <>
            <ToastContainer />
            <Box sx={{ m: 2 }} />
            <Typography variant="h5" align="center">
                Add User
            </Typography>
            <IconButton
                style={{ position: "absolute", top: "0", right: "0" }}
                onClick={closeEvent}
            >
                <CloseIcon />
            </IconButton>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            {...register("name")}
                            name="name"
                            label="Name"
                            size="small"
                            sx={{ marginTop: "30px", minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.name?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            {...register("email")}
                            name="email"
                            label="Email"
                            size="small"
                            sx={{ marginTop: "30px", minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.email?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={password}
                            name="password"
                            label="Password"
                            onChange={handlePasswordChange}
                            type="password"
                            size="small"
                            sx={{ minWidth: "100%" }}
                        />
                        {/* <p className="text-sm text-orange-600">{errors.password?.message}</p> */}
                    </Grid>
                    {/* <Grid item xs={6}>
                        <TextField
                            {...register("confirmpassword")}
                            name="confirmpassword"
                            label="Confirm Password"
                            size="small"
                            type="password"
                            sx={{ minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.confirmpassword?.message}</p>
                    </Grid> */}
                    <Grid item xs={6}>
                        <FormControl
                            variant="outlined"
                            sx={{ minWidth: "100%" }}
                            size="small"
                        >
                            <InputLabel id="branch-label">Type*</InputLabel>
                            <Select
                                labelId="branch-label"
                                size="small"
                                label="Type*"
                                onChange={handleTypeChange}
                                value={type}
                                name="type"
                            >
                                <MenuItem value="User">User</MenuItem>
                                {utype == "Admin" && (
                                    <>
                                        <MenuItem value="Admin">Admin</MenuItem>
                                        <MenuItem value="Project Head">Project Head</MenuItem>
                                    </>
                                )}
                                {utype == "Project Head" && (
                                    <>
                                        <MenuItem value="Area Coordinator">Area Coordinator</MenuItem>
                                    </>
                                )}
                                {utype == "Area Coordinator" && (
                                    <>
                                        <MenuItem value="District Coordinator">District Coordinator</MenuItem>
                                    </>
                                )}
                                {utype == "District Coordinator" && (
                                    <>
                                        <MenuItem value="BC Agent">BC Agent</MenuItem>
                                    </>
                                )}
                            </Select>
                        </FormControl>
                        <p className="text-sm text-orange-600">{errors.type?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            variant="outlined"
                            sx={{ minWidth: "100%" }}
                            size="small"
                        >
                            <InputLabel id="branch-label">Status</InputLabel>
                            <Select
                                size="small"
                                value={status}
                                onChange={handleStatusChange}
                                name="status"
                                label="Status"
                            >
                                <MenuItem value="Active">Active</MenuItem>
                                <MenuItem value="Inactive">Inactive</MenuItem>
                            </Select>
                        </FormControl>
                        <p className="text-sm text-orange-600">{errors.status?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            {...register("phone")}
                            name="phone"
                            label="Phone"
                            size="small"
                            type="text"
                            sx={{ minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.phone?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            {...register("reason")}
                            name="reason"
                            label="Reason"
                            size="small"
                            type="text"
                            sx={{ minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.reason?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            {...register("pincode")}
                            name="pincode"
                            label="Pincode"
                            size="small"
                            type="text"
                            sx={{ minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.pincode?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            {...register("koid")}
                            name="koid"
                            label="KOID"
                            size="small"
                            type="text"
                            sx={{ minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.koid?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            variant="outlined"
                            sx={{ minWidth: "100%" }}
                            size="small"
                        >
                            <InputLabel id="branch-label">Availability</InputLabel>
                            <Select
                                size="small"
                                name="availability"
                                value={availability}
                                onChange={handleAvailabilityChange}
                                label="Availability"
                            >
                                <MenuItem value="Online">Online</MenuItem>
                                <MenuItem value="Offline">Offline</MenuItem>
                            </Select>
                        </FormControl>
                        <p className="text-sm text-orange-600">{errors.availability?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            {...register("state")}
                            label="State"
                            name="state"
                            size="small"
                            type="text"
                            sx={{ minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.state?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            {...register("district")}
                            name="district"
                            label="District"
                            size="small"
                            type="text"
                            sx={{ minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.district?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            {...register("address")}
                            name="address"
                            label="Address"
                            size="small"
                            type="text"
                            sx={{ minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.address?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            variant="outlined"
                            sx={{ minWidth: "100%" }}
                            size="small"
                        >
                            <InputLabel id="branch-label">Select Loanwiser</InputLabel>
                            <Select
                                size="small"
                                value={loanwiser}
                                onChange={handleLoanwiserChange}
                                name="loanwiser"
                                label="Loanwiser"
                            >
                                <MenuItem value="">Select Loanwiser</MenuItem>
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </Select>
                        </FormControl>
                        <p className="text-sm text-orange-600">{errors.status?.message}</p>
                    </Grid>
                    {/* <Grid item xs={6}>
                        <TextField
                            {...register("loanwiser")}
                            name="loanwiser"
                            label="Loanwiser"
                            size="small"
                            type="text"
                            sx={{ minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.loanwiser?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            {...register("loanwiserpincode")}
                            name="loanwiserpincode"
                            label="Loanwiser Pincode"
                            size="small"
                            type="text"
                            sx={{ minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.loanwiserpincode?.message}</p>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Typography variant="h5" align="center">
                            <Button variant="contained" type="submit">
                                Submit
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}
