import React from "react";
import { Typography, Box, Card } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useAppStore } from "../appStore";
import axios from "axios";
import Sidenav from "../components/Sidenav";
import Navbar from "../components/Navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
    .object({
        no_of_withdrawal: yup.string().required(),
        no_of_deposite: yup.string().required(),
        total_deposite_amount: yup.string().required(),
        total_withdrawal_amount: yup.string().required(),
        no_of_fund_transfer: yup.string().required(),
        total_transfer_amount: yup.string().required(),
        no_of_account_open: yup.string().required(),
    })
    .required();

export default function AddBcaData() {
    const baseURL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const token = useAppStore((state) => state.token);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    const onSubmit = async (data) => {
        createUser(data);
    };

    const createUser = async (data) => {
        let posturl = baseURL + "bca";
        const tid = toast.loading("Please wait...");

        await axios
            .post(
                posturl,
                data,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "Application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(function (res) {
                toast.update(tid, {
                    render: "Created!",
                    type: "success",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    isLoading: false,
                });
                navigate("/daily-bca-report");
            })
            .catch(function (error) {
                toast.update(tid, {
                    render: error,
                    type: "error",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    isLoading: false,
                    theme: "light",
                });
                console.log("kcheckpost" + error); //return 429
            });
    };


    return (
        <>
            <ToastContainer />
            <div className="bgcolor">
                <Navbar />
                <Box height={70} />
                <Box sx={{ display: "flex" }}>
                    <Sidenav />
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Card
                            sx={{
                                width: "98%",
                                overflow: "hidden",
                                padding: "12px",
                            }}
                        >
                            <Typography variant="h5" align="center">
                                Add Daily BCA Report
                            </Typography>
                            <Box height={10} />
                            <IconButton
                                style={{
                                    position: "absolute",
                                    top: "-10",
                                    left: "20",
                                }}
                                onClick={() => {
                                    navigate("/daily-bca-report");
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Box height={20} />
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="No of Withdrawal"
                                            {...register("no_of_withdrawal")}
                                            name="no_of_withdrawal"
                                            type="number"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                                marginTop: "30px",
                                            }}
                                        />
                                        <p className="text-sm text-orange-600">{errors.no_of_withdrawal?.message}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("no_of_deposite")}
                                            name="no_of_deposite"
                                            label="No Of Deposite"
                                            type="number"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                                marginTop: "30px",
                                            }}
                                        />
                                        <p className="text-sm text-orange-600">{errors.no_of_deposite?.message}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("total_deposite_amount")}
                                            name="total_deposite_amount"
                                            label="Total Deposite Amount"
                                            type="number"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                        <p className="text-sm text-orange-600">{errors.total_deposite_amount?.message}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("no_of_fund_transfer")}
                                            name="no_of_fund_transfer"
                                            label="No of Fund Transfer"
                                            type="number"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                        <p className="text-sm text-orange-600">{errors.no_of_fund_transfer?.message}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("no_of_account_open")}
                                            name="no_of_account_open"
                                            label="No of Account Open"
                                            type="number"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                        <p className="text-sm text-orange-600">{errors.no_of_account_open?.message}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("total_transfer_amount")}
                                            name="total_transfer_amount"
                                            label="Total Transfer Amount"
                                            type="number"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                        <p className="text-sm text-orange-600">{errors.total_transfer_amount?.message}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("total_withdrawal_amount")}
                                            name="total_withdrawal_amount"
                                            label="Total withdrawal Amount"
                                            type="number"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("recurring_deposite")}
                                            name="recurring_deposite"
                                            label="Recurring Deposite"
                                            type="number"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                        <p className="text-sm text-orange-600">{errors.recurring_deposite?.message}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("fixed_deposite")}
                                            name="fixed_deposite"
                                            label="Fixed Deposite"
                                            type="number"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("fixed_deposite_amt")}
                                            name="fixed_deposite_amt"
                                            label="Fixed Deposite Amt"
                                            type="number"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("recurring_deposite_amt")}
                                            name="recurring_deposite_amt"
                                            label="Recurring Deposite Amt"
                                            type="number"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("shg_withdrawal")}
                                            name="shg_withdrawal"
                                            label="SHG Withdrawal"
                                            type="number"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("shg_withdrawal_amt")}
                                            name="shg_withdrawal_amt"
                                            label="SHG Withdrawal Amt"
                                            type="number"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("shg_deposite")}
                                            name="shg_deposite"
                                            label="SHG Deposite"
                                            type="number"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("shg_deposite_amt")}
                                            name="shg_deposite_amt"
                                            label="SHG Deposite Amt"
                                            type="number"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("apy")}
                                            name="apy"
                                            label="APY Done"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("pmsby")}
                                            name="pmsby"
                                            label="PMSBY Done"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("pmjjby")}
                                            name="pmjjby"
                                            label="PMJJBY Done"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant="h5" align="center">
                                            <Button
                                                variant="contained"
                                                type="submit"
                                            >
                                                Submit
                                            </Button>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </form>
                        </Card>
                    </Box>
                </Box>
            </div>
        </>
    );
}
