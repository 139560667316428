import React, { useEffect, useState } from "react";
import { Typography, Box, Card } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useAppStore } from "../appStore";
import axios from "axios";
import Sidenav from "../components/Sidenav";
import Navbar from "../components/Navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const schema = yup
    .object({
        // no_of_withdrawal: yup.string().required(),
    })
    .required();

export default function EditBcaDataProfile() {
    const baseURL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const token = useAppStore((state) => state.token);
    const [slbcValue, setSlbcValue] = useState("");
    const [districtValue, setDistrictValue] = useState("");
    const location = useLocation();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    useEffect(() => {
        console.log("FID: ", location.state);
        reset(location.state)
        setSlbcValue(location.state.check)
        setDistrictValue(location.state.district)
        // setName(location.state.name);

    }, []);

    const onSubmit = async (data) => {
        // console.log(data);
        Object.assign(data, { district: districtValue });
        Object.assign(data, { check: slbcValue });
        createUser(data);
    };

    const createUser = async (data) => {
        let posturl = baseURL + "bcaprofile/" + location.state.id;
        const tid = toast.loading("Please wait...");

        await axios
            .put(
                posturl,
                data,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "Application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(function (res) {
                toast.update(tid, {
                    render: "Created!",
                    type: "success",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    isLoading: false,
                });
                navigate("/bca-profile-report");
            })
            .catch(function (error) {
                toast.update(tid, {
                    render: error,
                    type: "error",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    isLoading: false,
                    theme: "light",
                });
                console.log("kcheckpost" + error); //return 429
            });
    };

    const handleSLBCChange = (event) => {
        setSlbcValue(event.target.value);
    }

    const handleDistrictChange = (event) => {
        setDistrictValue(event.target.value);
    }


    return (
        <>
            <ToastContainer />
            <div className="bgcolor">
                <Navbar />
                <Box height={70} />
                <Box sx={{ display: "flex" }}>
                    <Sidenav />
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Card
                            sx={{
                                width: "98%",
                                overflow: "hidden",
                                padding: "12px",
                            }}
                        >
                            <Typography variant="h5" align="center">
                                Edit BCA Profile Report
                            </Typography>
                            <Box height={10} />
                            <IconButton
                                style={{
                                    position: "absolute",
                                    top: "-10",
                                    left: "20",
                                }}
                                onClick={() => {
                                    navigate("/bca-profile-report");
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Box height={20} />
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Name"
                                            {...register("name")}
                                            name="name"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                                marginTop: "30px",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Phone"
                                            {...register("phone")}
                                            name="phone"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                                marginTop: "30px",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Email"
                                            {...register("email")}
                                            name="email"
                                            readonly={true}
                                            disabled={true}
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="KOID"
                                            {...register("koid")}
                                            name="koid"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Location"
                                            {...register("location")}
                                            name="location"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/* <TextField
                                            label="District"
                                            {...register("district")}
                                            name="district"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        /> */}
                                        <FormControl
                                            sx={{ minWidth: "100%" }}
                                            size="small"
                                        >
                                            <InputLabel id="dropdown-label">District</InputLabel>
                                            <Select
                                                size="small"
                                                value={districtValue}
                                                onChange={handleDistrictChange}
                                            >
                                                {/* Define individual menu items */}
                                                <MenuItem value="">Select District</MenuItem>
                                                <MenuItem value="SLBC">SLBC Type</MenuItem>
                                                <MenuItem value="Nalanda">Nalanda</MenuItem>
                                                <MenuItem value="Nalanda2">Nalanda2</MenuItem>
                                                <MenuItem value="Nawada">Nawada</MenuItem>
                                                <MenuItem value="Gaya">Gaya</MenuItem>
                                                <MenuItem value="Patna">Patna</MenuItem>
                                                <MenuItem value="Jehanabad">Jehanabad</MenuItem>
                                                <MenuItem value="Arwal">Arwal</MenuItem>
                                                <MenuItem value="Kaimur">Kaimur</MenuItem>
                                                <MenuItem value="Rohtas">Rohtas</MenuItem>
                                                <MenuItem value="Bhojpur">Bhojpur</MenuItem>
                                                <MenuItem value="Buxar">Buxar</MenuItem>
                                                <MenuItem value="Aurangabad">Aurangabad</MenuItem>
                                                <MenuItem value="Allahabad">Allahabad</MenuItem>
                                                <MenuItem value="Ambedkar Nagar">Ambedkar Nagar</MenuItem>
                                                <MenuItem value="Auraiya">Auraiya</MenuItem>
                                                <MenuItem value="Azamgarh">Azamgarh</MenuItem>
                                                <MenuItem value="Bahraich">Bahraich</MenuItem>
                                                <MenuItem value="Balrampur">Balrampur</MenuItem>
                                                <MenuItem value="Basti">Basti</MenuItem>
                                                <MenuItem value="Faizabad">Faizabad</MenuItem>
                                                <MenuItem value="Fatehpur">Fatehpur</MenuItem>
                                                <MenuItem value="Gonda">Gonda</MenuItem>
                                                <MenuItem value="Kannauj">Kannauj</MenuItem>
                                                <MenuItem value="Kanpur Dehat">Kanpur Dehat</MenuItem>
                                                <MenuItem value="Kanpur Nagar">Kanpur Nagar</MenuItem>
                                                <MenuItem value="Lucknow">Lucknow</MenuItem>
                                                <MenuItem value="Mau">Mau</MenuItem>
                                                <MenuItem value="Mirzapur">Mirzapur</MenuItem>
                                                <MenuItem value="Pratapgarh">Pratapgarh</MenuItem>
                                                <MenuItem value="Rai Bareilly">Rai Bareilly</MenuItem>
                                                <MenuItem value="Shrawasti">Shrawasti</MenuItem>
                                                <MenuItem value="Sidharth Nagar">Sidharth Nagar</MenuItem>
                                                <MenuItem value="Sitapur">Sitapur</MenuItem>
                                                <MenuItem value="Sultanpur">Sultanpur</MenuItem>
                                                <MenuItem value="Unnao">Unnao</MenuItem>
                                                <MenuItem value="Varanasi">Varanasi</MenuItem>
                                                <MenuItem value="Darbhanga">Darbhanga</MenuItem>
                                                <MenuItem value="East Champaran">East Champaran</MenuItem>
                                                <MenuItem value="Madhubani">Madhubani</MenuItem>
                                                <MenuItem value="Muzaffarpur">Muzaffarpur</MenuItem>
                                                <MenuItem value="Samastipur">Samastipur</MenuItem>
                                                <MenuItem value="Siwan">Siwan</MenuItem>
                                                <MenuItem value="West Champaran">West Champaran</MenuItem>
                                                <MenuItem value="ARWAL">ARWAL</MenuItem>
                                                <MenuItem value="BHOJPUR">BHOJPUR</MenuItem>
                                                <MenuItem value="Banka">Banka</MenuItem>
                                                <MenuItem value="KHAGARIA">KHAGARIA</MenuItem>
                                                <MenuItem value="Munger">Munger</MenuItem>
                                                <MenuItem value="Saharsa">Saharsa</MenuItem>
                                                <MenuItem value="Supaul">Supaul</MenuItem>
                                                <MenuItem value="RANCHI">RANCHI</MenuItem>
                                                <MenuItem value="DHANBAD">DHANBAD</MenuItem>
                                                <MenuItem value="DUMKA">DUMKA</MenuItem>
                                                <MenuItem value="JAMTARA">JAMTARA</MenuItem>
                                                <MenuItem value="GODDA">GODDA</MenuItem>
                                                <MenuItem value="SAHEBGANJ">SAHEBGANJ</MenuItem>
                                                <MenuItem value="PAKUR">PAKUR</MenuItem>
                                                <MenuItem value="BOKARO">BOKARO</MenuItem>
                                                <MenuItem value="EAST SINGHBHUM">EAST SINGHBHUM</MenuItem>
                                                <MenuItem value="West SINGHBHUM">West SINGHBHUM</MenuItem>
                                                <MenuItem value="SERAIKELA KHARSAWAN">SERAIKELA KHARSAWAN</MenuItem>
                                                <MenuItem value="HAZARIBAGH">HAZARIBAGH</MenuItem>
                                                <MenuItem value="KODERMA">KODERMA</MenuItem>
                                                <MenuItem value="RAMGARH">RAMGARH</MenuItem>
                                                <MenuItem value="BARABANKI">BARABANKI</MenuItem>
                                                <MenuItem value="BANDA">BANDA</MenuItem>
                                                <MenuItem value="HARDOI">HARDOI</MenuItem>
                                                <MenuItem value="JHANSI">JHANSI</MenuItem>
                                                <MenuItem value="KANPUR">KANPUR</MenuItem>
                                                <MenuItem value="Anand">Anand</MenuItem>
                                                <MenuItem value="BHARUCH">BHARUCH</MenuItem>
                                                <MenuItem value="PANCHMAHALS">PANCHMAHALS</MenuItem>
                                                <MenuItem value="Surat">Surat</MenuItem>
                                                <MenuItem value="Vadodara">Vadodara</MenuItem>
                                                <MenuItem value="BARPETA">BARPETA</MenuItem>
                                                <MenuItem value="BISWANATH">BISWANATH</MenuItem>
                                                <MenuItem value="CACHAR">CACHAR</MenuItem>
                                                <MenuItem value="DIMA HASAO">DIMA HASAO</MenuItem>
                                                <MenuItem value="JORHAT">JORHAT</MenuItem>
                                                <MenuItem value="KARIMGANJ">KARIMGANJ</MenuItem>
                                                <MenuItem value="NALBARI">NALBARI</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="SSA Name"
                                            {...register("ssa_name")}
                                            name="ssa_name"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="SSA Code"
                                            {...register("ssa_code")}
                                            name="ssa_code"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Base Branch"
                                            {...register("base_branch")}
                                            name="base_branch"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Base Branch Code"
                                            {...register("base_branch_code")}
                                            name="base_branch_code"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Base Branch Mobile"
                                            {...register("base_branch_mobile")}
                                            name="base_branch_mobile"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Gender (Male / Female)"
                                            {...register("gender")}
                                            name="gender"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="DOB"
                                            {...register("dob")}
                                            name="dob"
                                            type="date"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Father / Husband Name"
                                            {...register("father_husband_name")}
                                            name="father_husband_name"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Educational Qualification"
                                            {...register("educational_qualification")}
                                            name="educational_qualification"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Bank Name"
                                            {...register("bank_name")}
                                            name="bank_name"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Agent Reg ID"
                                            {...register("agent_reg_id")}
                                            name="agent_reg_id"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Aadhaar"
                                            {...register("aadhaar")}
                                            name="aadhaar"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Pan"
                                            {...register("pan")}
                                            name="pan"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Current Account"
                                            {...register("current_account")}
                                            name="current_account"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="RO Name"
                                            {...register("ro_name")}
                                            name="ro_name"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="RO Mobile No"
                                            {...register("ro_mobile_no")}
                                            name="ro_mobile_no"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/* <TextField
                                            label="SLBC"
                                            {...register("check")}
                                            name="check"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        /> */}
                                        <FormControl
                                            sx={{ minWidth: "100%" }}
                                            size="small"
                                        >
                                            <InputLabel>SLBC</InputLabel>
                                            <Select
                                                size="small"
                                                value={slbcValue}
                                                onChange={handleSLBCChange}
                                            >
                                                {/* Define individual menu items */}
                                                <MenuItem value="SLBC">SLBC Type</MenuItem>
                                                <MenuItem value="Non SLBC">Non SLBC</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant="h5" align="center">
                                            <Button
                                                variant="contained"
                                                type="submit"
                                            >
                                                Submit
                                            </Button>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </form>
                        </Card>
                    </Box>
                </Box>
            </div>
        </>
    );
}
