import { useEffect, useState } from "react";
import Sidenav from "../components/Sidenav";
import Box from "@mui/material/Box";
import Navbar from "../components/Navbar";
import Sent from "../messages/sent";
import Received from "../messages/received";
import "../Dash.css";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAppStore } from "../appStore";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";


const schema = yup
  .object({
    message: yup.string().required(),
  })
  .required();

export default function Messages() {
  const baseURL = process.env.REACT_APP_API_URL;
  const token = useAppStore((state) => state.token);
  const useremail = useAppStore((state) => state.useremail);
  const [messages, setMessages] = useState("");
  const [email, setEmail] = useState("");
  const chatusers = useAppStore((state) => state.chatusers);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = async (data) => {
    console.log('Chat Data ', data);
    Object.assign(data, { type: 'Only Selected Email' });
    Object.assign(data, { email: email });
    createUser(data);
  };

  useEffect(() => {
    if (!token) {
      navigate("/");
    }

    getUsers();
  }, []);

  const getUsers = async () => {
    let posturl = baseURL + "chat";
    await axios
      .get(posturl, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log('Messages ', res.data);
        setMessages(res.data);
      })
      .catch((err) => {
        console.log("kcheckpost" + err); //return 429
      });
  };

  const createUser = async (data) => {
    let posturl = baseURL + "chat";
    const tid = toast.loading("Please wait...");

    await axios
      .post(
        posturl,
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "Application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (res) {
        toast.update(tid, {
          render: "Created!",
          type: "success",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          isLoading: false,
        });
        reset({ email: "", message: "" })
        setEmail('');
        getUsers();
      })
      .catch(function (error) {
        toast.update(tid, {
          render: error,
          type: "error",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          isLoading: false,
          theme: "light",
        });
        console.log("kcheckpost" + error); //return 429
      });
  };

  const filterData = (v) => {
    console.log('v ', v.email);
    setEmail(v.email)
    // if (v) {
    //   // setRows([v]);
    //   updateUsers([v]);
    // } else {
    //   getUsers();
    // }
  };

  return (
    <>
      <ToastContainer />
      <div className="bgcolor">
        <Navbar />
        <Box height={70} />
        <Box sx={{ display: "flex" }}>
          <Sidenav />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <div className="flex justify-center">
              <h1 className="text-xl text-bold">Messages</h1>
            </div>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={chatusers}
                      sx={{
                        minWidth: "100%",
                        marginTop: "30px",
                      }}
                      onChange={(e, v) => filterData(v)}
                      getOptionLabel={(users) => users.email || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Search User"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      label="Message"
                      {...register("message")}
                      name="message"
                      type="text"
                      size="small"
                      sx={{
                        minWidth: "100%",
                        marginTop: "30px",
                      }}
                    />
                    <p className="text-sm text-orange-600">{errors.message?.message}</p>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h5" align="center">
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          minWidth: "100%",
                          marginTop: "30px",
                        }}
                      >
                        Send
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            </div>
            <div className="grid grid-cols-2 mt-4 gap-4">
              <div className="rounded-lg shadow-xl p-4 bg-white">
                <Sent messages={messages} />
              </div>
              <div className="rounded-lg shadow-xl p-4 bg-white">
                <Received />
              </div>
            </div>
          </Box>
        </Box>
      </div >
    </ >
  );
}
